import React, { useState } from 'react';
import axios from 'axios'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { Form, Button, Alert } from 'react-bootstrap'

import '../../css/main.css';
import * as rain from '../../rain.jpg';

const apiUrl = process.env.REACT_APP_API_BASE_URL

export const Dashboard = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")

  const submitForm = async (event) => {
    if (!executeRecaptcha) {
      setError('Captcha is not yet available. Please try again in a moment.');
      return;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log('form not valid')
      return;
    }
    console.log('form valid!')
    setIsLoading(true);

    const token = await executeRecaptcha('yourAction');
    // Do whatever you want with the token
    console.log(token)

    axios.post(`${apiUrl}/mailing`, {
      email,
      token,
    })
      .then(() => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err.toString());
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    submitForm(event)
    return false
  };

  return (
    <>
      <main role="main" className="flex-shrink-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
          <a className="navbar-brand" href="#">OCD Village</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                  aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div className="container-fluid mb-md-4">
          <div className="row">
              <div className="rain" src={ rain }>
                <h1>Welcome to OCD Village!</h1>
                Coming soon
            </div>
          </div>
        </div>

        <div className="container" style={{marginBottom: "25px"}}>
          <div className="row justify-content-md-center">
            <div className="col col-lg-3 col-md-6 col-sm-12" style={{textAlign: "center"}}>
              { success ? <Alert variant="success">Thank you for signing up!</Alert> : (
                <Form onSubmit={handleSubmit}>
                  <h2>Join our email list to stay up to date</h2>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Control type="email" placeholder="name@example.com" required onChange={(e) => {
                      setEmail(e.target.value)
                    }} />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>
                  <Button
                    type="submit"
                    style={{width: "100%"}}
                    disabled={isLoading}
                  >
                    Join Email List
                  </Button>
                  { error && <Alert variant="danger">{ error }</Alert>}
                </Form>
              )}
            </div>
          </div>
        </div>
      </main>
      <footer className="footer mt-auto py-3">
        <div className="container">
          <span className="text-muted">Copyright &copy; 2022 OCD Village</span>
        </div>
      </footer>
    </>
  );
};

export default Dashboard;