import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Dashboard from './components/dashboard';

import '../node_modules/font-awesome/css/font-awesome.min.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="*">
          <Dashboard />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
